<template>
  <b-card>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon
              icon="BookOpenIcon"
              size="16"
              class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Training</span>

        </template>
        <training-edit-tab :training-data="trainingData"  class="mt-2 pt-75" />

      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
              icon="UserPlusIcon"
              size="16"
              class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Assign Employee To Training</span>
        </template>
        <training-assign-employee-tab :training-id="this.id" />
      </b-tab>
    </b-tabs >

</b-card>

</template>

<script>
  import {
    BCardBody,  BFormInvalidFeedback, BTab, BTabs,BForm,  VBToggle,  VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar
} from 'bootstrap-vue'
import TrainingEditTab from '@/views/hr/orientation/training/TrainingEditTab'
import TrainingAssignEmployeeTab from '@/views/hr/orientation/training/TrainingAssignEmployeeTab'
import { ref, onUnmounted, onMounted, onBeforeMount, provide } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import orientationStoreModule from '@/views/hr/orientation/orientationStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useToast } from 'vue-toastification/composition'
export default {
  name: 'training-edit',
  components: {
    TrainingAssignEmployeeTab,
    BCardBody, BFormInvalidFeedback,  BTab,  BTabs,BForm,   TrainingEditTab, VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar,
  },
  data(){
    return {
      id: router.currentRoute.params.id
    }
  },

  setup(){
    const trainingData =ref({})
    const toast = useToast()
    const TRAINING_APP_STORE_MODULE = 'hr-training'
    // Register module
    if (!store.hasModule(TRAINING_APP_STORE_MODULE)) store.registerModule(TRAINING_APP_STORE_MODULE, orientationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRAINING_APP_STORE_MODULE)) store.unregisterModule(TRAINING_APP_STORE_MODULE)
    })

    onMounted(() => {
      store.dispatch('fetchTraining', { id: router.currentRoute.params.id })
          .then(response => {
            trainingData.value = response.data
          })
          .catch(error => {

            if (error.response.status === 404) {
              trainingData.value = {}
            }
          })
    })

    return {
      trainingData
    }
  }

}
</script>

<style scoped>

</style>
