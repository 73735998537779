<template>
  <div>
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
            cols="12"
            md="6"
        >
          <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
          >
          <b-form-group>
            <h5>Training Name</h5>
            <b-form-input
                id="name"
                v-model="trainingData.name"
                :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>

          </validation-provider>
        </b-col>
        <!-- Field: Description -->
        <b-col
            cols="12"
            md="6"
        >

          <b-form-group>
            <h5>Description</h5>
            <b-form-textarea
                v-model = "trainingData.description"
                id="description"
                placeholder="Textarea"
                rows="3"

            />
          </b-form-group>


        </b-col>

        <!-- Field: Trainer -->
        <b-col
            cols="12"
            md="6"
        >
          <b-form-group>
            <h5>Trainer</h5>
            <b-form-input
                id="trainer"
                v-model="trainingData.trainer"
            />
          </b-form-group>


        </b-col>
        <!-- Field: Room -->
        <b-col
            cols="12"
            md="6"
        >
          <b-form-group>
            <h5>Room</h5>
            <b-form-input
                id="room"
                v-model="trainingData.room"
            />
          </b-form-group>


        </b-col>

        <!-- Field: Start Date -->
        <b-col
            cols="12" md="6">
          <b-form-group>
            <h5>Start Date&Time</h5>
            <flat-pickr
                v-model="trainingData.startDate"
                class="form-control"
                :config="{ enableTime: true,minuteIncrement:1, minDate: 'today'}"
            />
          </b-form-group>
        </b-col>
        <!-- Field: End Date -->
        <b-col
          cols="12" md="6">
        <b-form-group>
          <h5>End Date&Time</h5>
          <flat-pickr
              v-model="trainingData.endDate"
              class="form-control"
              :config="{ enableTime: true,minuteIncrement:1, minDate: trainingData.startDate}"
          />
        </b-form-group>
      </b-col>

        <!-- Field: End Date -->
        <b-col
            cols="12" md="6">
          <b-form-group>
            <h5>Training Type</h5>
            <v-select
                v-model="trainingData.trainingType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="trainingTypes"
                :clearable="false"
                label="name"
                input-id="trainingType"
            />
          </b-form-group>
        </b-col>


      </b-row>
    </b-form>
    <!-- Action Buttons -->
    <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="onSubmit"
    >
      Save Changes
    </b-button>
    <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :to="{name: 'trainings-page'  }"
    >
      Cancel
    </b-button>
  </div>

</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import router from '@/router'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
  BCardBody, BFormInvalidFeedback,  BTab,  BTabs,BForm,  VBToggle,   VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
 BFormTextarea, BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar
} from 'bootstrap-vue'
export default {
  name: 'TrainingEditTab',
  components: {
    flatPickr,
    ToastificationContent,
    vSelect, VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BCardBody, BFormInvalidFeedback,  BTab,  BTabs,BForm,  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar,
    ValidationProvider,BFormTextarea,
    ValidationObserver
  },
  props: {
    trainingData: {
      type: Object,
      required: true
    }
  },
  setup(props){

    const blankTrainingData = {
      Name: '',
      Description: '',
      Trainer: '',
      Room: '',
      StartDate: null,
      EndDate: null,
      TrainingType: '',

    }

    const trainingData = ref(JSON.parse(JSON.stringify(blankTrainingData)))
    const resettrainingData = () => {
      trainingData.value = JSON.parse(JSON.stringify(blankTrainingData))
    }
    const trainingTypes = [{name:"General"}]
    const toast = useToast()
    const onSubmit = () => {
      //console.log("test4"+  props.trainingData)
      store.dispatch('updateTraining',props.trainingData)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success updating training',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        router.push({name:'orientationtrainings-page'})
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating training',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resettrainingData)

    return {
      onSubmit,
      trainingTypes,
      refFormObserver,
      getValidationState,
      resetForm,


    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
